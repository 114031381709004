import React from 'react';
import { withRouter} from 'react-router-dom';


import LandingLayout from 'component/layout/page/landing';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="customizations-page">
        

      </LandingLayout>
    )
  }
}

export default withRouter(Page);

// themes
// font
// sizes
// colours
// 

