import React from 'react';
import { withRouter} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import LandingLayout from 'component/layout/page/landing';

import Features from        'component/content/features';
import CTA from        'component/content/cta';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="landing-page" className="light-bg-1 py-5">
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>


        <Features />

        <CTA />


      </LandingLayout>
    )
  }
}

export default withRouter(Page);
