import React from 'react';
// import Slide from 'react-reveal/Slide';

import {Parallax} from 'react-scroll-parallax';
import {Title} from 'component/global/title';


const _features = [
  {
    "title": "Features you'll love", 
    "description": "INTERACTIVE VIDEO - DYNAMIC CUSTOMIZATIONS - INCREASED ROI", 
    "points": [
      [{text: " Create a video in "}, {text: " under 5 minutes ", emphasize: true}, ],
      [{text: "  "}, {text: " Add links and forms ", emphasize: true}, {text: " to your videos"}],
      [{text: " Create once, "}, {text: " modify forever", emphasize: true}, {text: ". Keep the same link! "}],
      [{text: " Ridiculously"}, {text: " easy integration", emphasize: true}, {text: ".  "}],
      [{text: " Export videos", emphasize: true}, {text: "  and upload to social sites"}],
    ], 
    'color': {'text': 'hsla(16, 87%, 49%, 1)', 'background': 'hsla(16, 87%, 49%, 0.05)',},
    'icon': 'fa fa-video',
    "media": {
      // "img": {"src": "/static/images/features/03.svg", "alt": "", "size": "md"},
      "slick_vid": {
        videoId: 'slickvid_do',
        thumb: 3,
        color: 'hsla(16, 87%, 49%, 1)',
        background: 'hsla(16, 87%, 49%, 0.02)',
      },
    },
  },
  {
    "title": "Fine tune your growth", 
    "description": "LEAD GEN - GROW SALES - SEO - ANALYTICS ", 
    "points": [
      [{text: " Generate leads with "}, {text: " subscription forms"}],
      [{text: " Advanced analytics", emphasize: true}, {text: " that aren't possible with video"}],
      [{text: " SEO", emphasize: true}, {text: "  built inside the recordings!"}],
      [{text: " Change content on the fly with "}, {text: " dynamic customization", emphasize: true}],
      [{text: " APIs available", emphasize: true}, {text: "  for programatical access"}],
    ], 
    'color': {'text': 'hsla(256, 87%, 49%, 1)', 'background': 'hsla(256, 87%, 49%, 0.05)',},
    'icon': 'fa fa-cog',
    "media": {
      // "img": {"src": "/static/images/features/02.svg", "alt": "", "size": "md"},
      "slick_vid": {
        videoId: 'slickvid_extra',
        thumb: 7,
        color: 'hsla(256, 87%, 49%, 1)',
        background: 'hsla(256, 87%, 49%, 0.02)',
      },
    }
  },
  {
    "title": "Who's using it?", 
    "description": "SMBs - HR / PEOPLE OPS - MARKETING - SALES", 
    "points": [
      [{text: " Sales", emphasize: true}, {text: " : Quick explainers, feature showcase"}],
      [{text: " Marketing", emphasize: true}, {text: " : Personalized reminders, offers, discounts"}],
      [{text: " PM", emphasize: true}, {text: " : Idea validation with "}, {text: " feedback forms", emphasize: true}],
      [{text: " HR ops", emphasize: true}, {text: " : annoucements, policy updates, celebrations"}],
      [{text: " Empower your entire team to create interactive videos"}, {text: " ", emphasize: true}],
    ], 
    'color': {'text': 'hsla(356, 87%, 49%, 1)', 'background': 'hsla(356, 87%, 49%, 0.05)',},
    'icon': 'fa fa-user',
    "media": {
      "img": {"src": "/static/images/features/01.svg", "alt": "", "size": "md"},
    }
  },
  {
    "title": "Consistency across brand", 
    "description": "MANAGE LEADS - ANALYZE - DRIVE ACTION", 
    "points": [
      [{text: " Get "}, {text: " quality leads", emphasize: true}, {text: "  with "}, {text: " signup forms"}],
      [{text: " Set colors and font for "}, {text: " global consistency", emphasize: true}],
      // [{text: " Analytics", emphasize: true}, {text: "  available on form submissions"}],
      [{text: " Empower all ", emphasize: true}, {text: "  employees to become creators!"}],
    ], 
    'color': {'text': 'hsla(276, 87%, 49%, 1)', 'background': 'hsla(276, 87%, 49%, 0.05)',},
    'icon': 'fa fa-star',
    "media": {
      "img": {"src": "/static/images/features/04.svg", "alt": "", "size": "md"},
      // "slick_vid": {
      //   size: 500,
      //   videoId: 'demo',
      //   thumb: '14',
      //   color: 'rgba(160,2,255, 0.05)',
      // },
    },
  },
]

export default class Features extends React.Component {
  render (){
    const is_mobile = window.innerWidth < 640;
    return (
      <section id="subscribe" className="content-section flex-center light-bg-1 ">

          <Title type="section" underline='primary' className={"text-center black-text-3 " + ((is_mobile)? 'text-size-10': 'text-size-14')} >
            {/* What <span className="primary-text-1">SlickVid</span> has to offer! */}
            Creatively Grow your <span className="primary-text-1">Brand Awareness</span> 
          </Title>

        <div className="container-fluid container " >
          {_features.map(function(feature, idx){return (
            <div 
              key={"_"+idx} 
              className={"" + ((idx%2===0)? 'black-text-1 ': '  black-text-1 ')}
              // style={((idx%2===0)? {}: {backgroundColor: '#fbfbfb'})}
            >
              <FeatureSection  item={feature} flip={idx%2 === 0}/>
            </div>
          )})}
        </div>
      </section>
    )
  }
}


class FeatureSection extends React.Component {
  render (){
    const is_mobile = window.innerWidth < 1200;
    const {item, flip } = this.props;

    const img_width = 6 ; 
    // {
    //   "sm": 4,
    //   "md": 6,
    //   "lg": 8,
    // }[item.img.size]

    return (
      // <div className="my-5 py-1">
      <div className="my-5 ">

        <div className={" white-bg-1 border row   d-flex align-items-center  " } style={{borderRadius: '15px', }}>


      {(flip)? '': <FeatureMedia item={item} img_width={img_width} flip={flip} />}
          {/* <Slide bottom> */}
        <div className={" col-md-" + (12 - img_width) + ((is_mobile && flip)? ' order-2 my-3': ' my-5')}>
          {/* <Parallax scale={[0.90, 1.0]} > */}
            <div className={"flex-left " + ((is_mobile)? ' mx-2 ': ' mx-5 ')}>
              <div className={" text-size-9 "+ ((is_mobile)? ' text-center ': '')}><i className={" p-3 " + item.icon } style={{backgroundColor: `${item.color.background}`, color: `${item.color.text}`, borderRadius: '50%'}}></i></div>
              <div className={" my-3 " + ((is_mobile)? ' text-size-9 ' : ' text-size-12 ')}>{item.title}</div>
              <div className={"text-size-2 my-3 rounded p-2 text-right"} style={{backgroundColor: `${item.color.background}`}} >{item.description}</div>
              <ul className="my-3 text-size-4 black-text-3 ml-3">
                {item.points.map(function(point, idx){
                  return (
                    <li key={"_"+idx} className=" my-1">
                      {point.map(function(point_section, in_idx){
                        return (
                          <span key={"_"+in_idx} className={"" + ((point_section.emphasize)? '  ': '')} style={((point_section.emphasize)? {color: `${item.color.text}`}: {})}>
                            {point_section.text}
                          </span>
                        )
                      })}
                    </li>
                  )
                })}
              </ul>
            </div>
          {/* </Parallax> */}
        </div>
          {/* </Slide> */}
        {(flip)? <FeatureMedia item={item} img_width={img_width} flip={flip} />: ""}

        </div>

      </div>
      
      // </div>
    )
  }
}

function FeatureMedia(props){
  const is_mobile = window.innerWidth < 1200;
  const {item, img_width, flip} = props;

  if (item.media.slick_vid){    
    const sv = item.media.slick_vid;

    if (is_mobile){
      sv.size = 350;
    }
    
    return(
      <div className={" col-md-"+ (img_width) + ((is_mobile && flip)? ' order-1 py-3': ' py-5')}>
      <Parallax scale={[0.85, 1.0]}>
        <div className="  p-5 " style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5%', }}>
          <div dangerouslySetInnerHTML={{__html: 
            `<SlickVid 
              data-video-id="${sv.videoId}" 
              ${(sv.thumb)? `data-thumbnail="${sv.thumb}"`: ''} 
              ${(sv.size)? `data-size="${sv.size}"`: ''} 
              ${(sv.color)? `data-color="${sv.color}"`: ''} 
              ${(sv.color)? `data-color="${sv.color}"`: ''}
              ></SlickVid>`
            }}/>
            {/* ${(sv.background)? `data-background-color="${sv.background}"`: ''}  */}
        </div>
      </Parallax>
    </div>
    )
  }

  return(
    <div className={" col-md-"+ (img_width) + ((is_mobile && flip)? ' order-1 my-3': ' my-5')}>
      <Parallax scale={[0.85, 1.0]}>
        <img className={"scroll img img-fluid rounded p-3" + (is_mobile? ' mx-auto d-block': ' ')} src={item.media.img.src} alt={item.media.img.alt} />
      </Parallax>
    </div>
  )

}