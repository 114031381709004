import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Router, Switch, Redirect } from 'react-router-dom';

import { ParallaxProvider } from 'react-scroll-parallax';

// import {createBrowserHistory} from 'history';
import createBrowserHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';

import LandingPage from './page/public/landing';
import FeaturesPage from './page/public/features';
import USPPage from './page/public/usp';
import StoryPage from './page/public/story';
import LoginPage from './page/public/login';
import LoginEmailPage from './page/public/login/email';
import NoMatchPage from './page/generic/no_match';
import ComingSoonPage from './page/generic/coming_soon';

import DemoBlogPage from './page/public/demo/blog';
import DemoCompanyPage from './page/public/demo/company';
import DemoCustomizationsPage from './page/public/demo/customizations';
import DemoTemplatePage from './page/public/demo/templates';
import DemoVideoEditPage from './page/public/demo/video_edit';

import UseCasePage from './page/public/usecases';
import UCDetailPage from './page/public/usecases/detail';

import LegalTOSPage from './page/legal/tos';
import PrivacyPolicyPage from './page/legal/privacy';

import * as serviceWorker from './serviceWorker';

import './styles/bootstrap.min.css';
import './styles/alertify.min.css';
import './index.css';


const history = createBrowserHistory();

history.listen(location => {
  // const on_sites = (location.pathname.match(/sites/gi) || []).length;
  // const on_instructions = (location.pathname.match(/instructions/gi) || []).length;
  // const on_no_scroll_page = (on_sites > 0 || on_instructions > 0);
  const on_no_scroll_page = false;
  if (on_no_scroll_page){

  } else {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }  
});


const trackingId = process.env.REACT_APP_GA_CODE;  
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname);

// const target = document.querySelectorAll('.scroll');

// var index = 0, length = target.length;
// for (index; index < length; index++){
//   var pos = window.pageYOffset * target[index].dataset.rate;
//   if (target[index].dataset.direction === 'vertical'){
//     target[index].style.transform = 'translate3d(0px,'+pos+'px, -px)';
//   } else {
//     var posX = window.pageYOffset * target[index].dataset.ratex;
//     var posY = window.pageYOffset * target[index].dataset.ratey;

//     target[index].style.transform = 'translate3d('+posX+'px,'+posY+'px, -px)';
//   }
// }


ReactDOM.render(
  <ParallaxProvider>
  <Router history={history}>
    <Switch>
      <Route exact path='/legal/tos' component={LegalTOSPage} />
      <Route exact path='/legal/privacy' component={PrivacyPolicyPage} />
      
      {/* Open pages */}
      <Route exact path='/features' component={FeaturesPage} />
      <Route exact path='/comparison' component={USPPage} />

      <Route exact path='/demo/customizations' component={DemoCustomizationsPage} />
      <Route exact path='/demo/company/:company_key' component={DemoCompanyPage} />
      <Route exact path='/demo/blog/:blog_key' component={DemoBlogPage} />

      <Route exact path='/company/:company_key/templates' component={DemoTemplatePage} />
      <Route exact path='/company/:company_key/videos/:video_id/edit' component={DemoVideoEditPage} />

      <Route exact path='/video/:video_xid/edit' render={() => ((false)? (<NoMatchPage />) : (<Redirect to={"/login?dest="+window.location.pathname}/>))}/>
      <Route exact path='/video/:video_xid/analytics' render={() => ((false)? (<NoMatchPage />) : (<Redirect to={"/login?dest="+window.location.pathname}/>))}/>
      <Route exact path='/video/:video_xid/copy' render={() => ((false)? (<NoMatchPage />) : (<Redirect to={"/login?dest="+window.location.pathname}/>))}/>
      <Route exact path='/video/:video_xid/export' render={() => ((false)? (<NoMatchPage />) : (<Redirect to={"/login?dest="+window.location.pathname}/>))}/>
      
      <Route exact path='/video/:video_xid/public' render={() => ((false)? (<NoMatchPage />) : (<Redirect to={"/login?dest="+window.location.pathname}/>))}/>
      <Route exact path='/video/:video_xid' render={() => ((false)? (<NoMatchPage />) : (<Redirect to={"/login?dest="+window.location.pathname}/>))}/>

      <Route exact path='/use-case' component={UseCasePage} />
      <Route exact path='/use-case/:use_case_key' component={UCDetailPage} />

      <Route exact path='/story' component={StoryPage} />
      <Route exact path='/documentation' component={ComingSoonPage} />
      <Route exact path='/login/email' component={LoginEmailPage} />
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/' component={LandingPage} />
      
      {/* Logged in pages */}
      {/* ---- NONE ---- */}

      <Route component={NoMatchPage} />

    </Switch>
  </Router>
  </ParallaxProvider>
  , document.getElementById('page')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
