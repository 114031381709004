import React from 'react';
import {notify} from 'react-notify-toast';

// import {TEXT} from 'component/global/constants';
import {Title} from 'component/global/title';

import {LeadsAPI, handleError} from 'utils/xhr';
import { getLeadID } from 'utils/authentication';


const CONTENT = {
  enterprise: {
    title: {
      page: "Reach out for access!",
      btn: 'Request access',
    },
    description: 'Work emails get prioritized',
    placeholder: "Work email",
    className: ' dark-bg-1 ',
  },
  subscribe: {
    title: {
      page: "Keep yourself updated!",
      btn: 'Subscribe',
    },
    description: 'For new ByteBeacon updates',
    placeholder: "Email address",
    className: ' primary-bg-1 ',
  },
  early_access: {
    title: {
      page: "Signup for early access",
      btn: 'Request access',
    },
    description: null,
    placeholder: "Email address",
    className: ' secondary-bg-1 ',
  }
}


export class PageCTA extends React.Component {
  render (){
    const is_mobile = window.innerWidth < 640;
    const category = this.props.category || 'subscribe'
    const inverted = this.props.inverted || false;
    var content = CONTENT[category];

    return (
      <section id="subscribe-page-section" className={" " + ((is_mobile)? 'p-1' : 'p-5')}>
        <div className="">
          <Title type="page" className="white-text-1 text-center" >{content.title.page}</Title>
          <div className="my-5 ">
            <Form category={category} inverted={inverted} />
          </div>
        </div>
      </section>
    )
  }
}

export class SectionCTA extends React.Component {
  render (){
    const category = this.props.category || 'subscribe'
    return (
      <section id="subscribe-section" className=" ">
        <Form full_width={true} category={category} />
      </section>
    )
  }
}

class Form extends React.Component {

  state = {
    email: '',
  }

  onSubscribe(e){
    e.preventDefault();

    if (!this.state.email){
      notify.show('Cannot submit a blank form!', "custom", 1000, { background: '#ac2222', text: "#ffffff" });
      return;
    }

    const url = '/public/leads';
    const data = {
      'lead_id': getLeadID(),
      'context': {
        'page': 'subscribe',
        'email': this.state.email,
      }
    }

    LeadsAPI.post(url, data).then(response =>{
      const state = this.state;
      state.email = '';
      notify.show('Subscribed!', "custom", 1000, { background: '#22ac35', text: "#FFFFFF" });
      this.setState(state);
    },(error) =>{
      notify.show('Error subscribing!', "custom", 1000, { background: '#ac2222', text: "#FFFFFF" });
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onChange(e){
    const state = this.state;
    state.email = e.target.value;
    this.setState(state);
  }
  
  render (){
    const is_mobile = window.innerWidth < 640;
    const force_full_width = this.props.full_width || false;
    const category = this.props.category || 'subscribe';
    // const inverted = this.props.inverted || false;
    var content = CONTENT[category];

    return (
      <div className={"from-group"}>
        <div className={"my-5 input-group  shadow rounded" + ((force_full_width)? '  ':'  ') }>
          <input 
            className={"form-control small text-size-4 " + ((is_mobile)? '' : 'py-3')}
            type="email" 
            placeholder={content.placeholder}
            onChange={this.onChange.bind(this)}
            value={this.state.email} />
          <div className="input-group-append">
            <a 
              className={" input-group-text text-size-4   white-text-1" + content.className} 
              style={{border: '0px'}} 
              href='/' 
              onClick={this.onSubscribe.bind(this)} 
            >
              {content.title.btn}
              <i className="fa fa-paper-plane ml-3"></i>
            </a>
          </div>
        </div>
        {(content.description)? (
          <p className={" text-center text-spacing-2 family-code white-text-1 "}>{content.description}</p>
        ): ('')}
      </div>
    )
  }
}
