import React from "react";
import {TEXT} from 'component/global/constants';


export default function SocialComponent(props){
  const contact = props.contact;

  const background = props.background || ' ';

  return (
    <div className="">
      {contact.linkedin ? <a aria-label="linkedin" href={contact.linkedin} className={"black-text-3 shadow mx-2 " + background}><i className="fab fa-linkedin-in"></i></a>: null }
      {contact.twitter ? <a aria-label="twitter" href={contact.twitter} className={"black-text-3 shadow mx-2 " + background}><i className="fab fa-twitter"></i></a>: null }
      {contact.facebook ? <a aria-label="facebook" href={contact.facebook} className={"black-text-3 shadow mx-2 " + background}><i className="fab fa-facebook-f"></i></a>: null }
      {contact.github ? <a aria-label="github" href={contact.github} className={"black-text-3 shadow mx-2 " + background}><i className="fab fa-github"></i></a>: null }
      {contact.quora ? <a aria-label="quora" href={contact.quora} className={"black-text-3 shadow mx-2 " + background}><i className="fab fa-quora"></i></a>: null }
      {contact.instagram ? <a aria-label="instagram" href={contact.instagram} className={"black-text-3 shadow mx-2 " + background}><i className="fab fa-instagram"></i></a>: null }
      {contact.docker ? <a aria-label="docker" href={contact.docker} className={"black-text-3 shadow mx-2 " + background}><i className="fab fa-docker"></i></a>: null }
    </div>
  )
}
