import React from "react";

import LightSpeed from 'react-reveal/LightSpeed';
// import Pulse from 'react-reveal/Pulse';


import {SectionCTA as SubscribeSectionCTA} from 'component/content/subscribe';

export default class HeroSection extends React.Component {

  render (){
    const style = {
      // 18, 100%, 72%
      // color: 'hsl(234, 59%, 61%)',
      // color: '-webkit-linear-gradient(to right, hsl(234, 59%, 75%), hsl(234, 59%, 61%))',
      // color: 'linear-gradient(to right, hsl(234, 59%, 75%), hsl(234, 59%, 61%))',
      // hsl(212, 89%, 51%)
      background: '-webkit-linear-gradient(hsl(212, 89%, 51%), hsl(212, 89%, 71%), hsl(212, 89%, 51%))',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    }


    const hero = {
      title: [
        [
          {class: '', text: " Grow your brand faster with ",}, 
          {class: ' strong', text: " interactive videos", style: style},
        ],
        // [{class: 'black-text-2', text: "Get better ROI.",}, ],
        // [{class: 'black-text-2', text: "Takes <5 mins",}, ],
      ],
      image: {
        path: '/static/images/hero.svg',
        alt: '"Try it free. No credit card required. Instant setup."',
      },
    }


    return (
      <section id="hero" className="" style={{height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <HeroTitleSubFeaturesImageComponent hero={hero} />
      </section>
    )
  }
}

function HeroTitleSubFeaturesImageComponent(props){
  const is_mobile = window.innerWidth < 1200;
  const hero = props.hero;

  const title = hero.title.map(function(line, idx){
    return (
      <div className="my-3" key={"" + idx}>
        {line.map(function(subline, sidx){
          return (<span key={''+sidx} style={subline.style} className={" " + subline.class}>{subline.text}</span>)
        })}
      </div>
    )
  })

  return (
    <div className={"row " + ((is_mobile)? " ": " ")} >

            <div className="" style={{zIndex: '-9999'}}>
              {(is_mobile)? (
                <div className=""></div>
              ):(
                <div className="">
                  <div className="" style={{borderRadius: '100%', position: 'absolute', border: '2px dashed hsla( 16, 87%, 19%, 0.3)', background: 'transparent', left: '0%', top: '0%', width: '10vw', height: '10vw',animation: 'bg-rotation 15s cubic-bezier(0.445, 0.05, 0.55, 0.95) both infinite', }}></div>
                  <div className="" style={{borderRadius: '100%', position: 'absolute', border: '2px dashed hsla(256, 87%, 19%, 0.3)', background: 'transparent', right: '10%', bottom: '0%', width: '15vw', height: '15vw',animation: 'bg-rotation 30s cubic-bezier(0.445, 0.05, 0.55, 0.95) both infinite', }}></div>
                  <div className="" style={{borderRadius: '100%', position: 'absolute', border: '2px dashed hsla(276, 87%, 19%, 0.3)', background: 'transparent', right: '40%', top: '18%', width: '13vw', height: '13vw',animation: 'bg-rotation 15s cubic-bezier(0.445, 0.05, 0.55, 0.95) both infinite', }}></div>

                  <div className="" style={{borderRadius: '100%', position: 'absolute', border: '2px dashed transparent', background: 'hsla( 16, 87%, 89%, 0.3)', right: '1%', top: '10%', width: '5vw', height: '5vw',animation: 'bg-pulse 5s cubic-bezier(0.445, 0.05, 0.55, 0.95) both infinite', }}></div>
                  <div className="" style={{borderRadius: '100%', position: 'absolute', border: '2px dashed transparent', background: 'hsla(256, 87%, 89%, 0.3)', left: '1%', top: '50%', width: '8vw', height: '8vw',animation: 'bg-pulse 5s cubic-bezier(0.445, 0.05, 0.55, 0.95) both infinite', }}></div>
                  {/* <div className="" style={{borderRadius: '100%', position: 'absolute', border: '2px dashed transparent', background: 'hsla(256, 87%, 19%, 0.3)', left: '2%', bottom: '15%', width: '8vw', height: '8vw',animation: 'bg-pulse 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) both infinite', }}></div> */}
                  <div className="" style={{borderRadius: '100%', position: 'absolute', border: '2px dashed transparent', background: 'hsla(276, 87%, 89%, 0.3)', left: '30%', bottom: '5%', width: '2vw', height: '2vw',animation: 'bg-pulse 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) both infinite', }}></div>
                </div>
              )}
            </div>

      <div className=" col-md col-sm-12 p-0 img img-fluid text-center align-self-center ">
        <div className="d-flex justify-content-center">
          <div className="my-3">


            <div className=" " dangerouslySetInnerHTML={{__html: `<SlickVid data-video-id="slickvid" data-thumbnail="14" ${(is_mobile)? `data-size="${Math.min(400, (window.innerWidth-50))}"`: 'data-size="500" '}></SlickVid>`}}/>

            {/* <pre className="my-3 quinary-text-1">
              {`<SlickVid data-video-id="product-intro"></SlickVid>`}
            </pre> */}
          </div>
        </div>
      </div> 
      
      <div className={" white-bg-1 black-text-1 col-md col-sm-12 text-center align-self-center " +((is_mobile)? ' px-1 ': ' px-3 ')}>

        <h1 className={"    " + ((is_mobile)? " text-center text-size-12 my-3 ": " text-left text-size-15 mt-5 mb-3 pb-3 ")}>
          {title}
        </h1>


        <div className={"text-dark text-left pt-0 " + ((is_mobile)? "  m-3 ": " my-1 ")}>
          <div className="my-3 ">
            <div className={" my-5 " + ((is_mobile)? ' text-size-2  ': ' text-size-4 ')} style={{}}>
              <div className="my-2">
                <div className=""><i className="fa fa-check mr-2 black-text-10"></i>No coding required!</div>
                <div className=""><i className="fa fa-check mr-2 black-text-10"></i>Add links, forms & widgets to videos</div>
                <div className=""><i className="fa fa-check mr-2 black-text-10"></i>Detailed Insights and Analytics</div>
                <div className=""><i className="fa fa-check mr-2 black-text-10"></i>Change video content without changing links</div>
                {/* <div className="">Step 1: Select a template</div> */}
                {/* <div className="">Step 2: Fill the form</div> */}
                {/* <div className="">Step 3: Add the video html to your website</div> */}
              </div>
            </div>
          </div>
        </div>


        <div className={" py-1 " + ((is_mobile)? " my-1 pb-3 ": "  pb-5 ")}>
          <LightSpeed left>
            <SubscribeSectionCTA category="early_access"  />
          </LightSpeed>
        </div>

      </div> 

    </div>
  )
}