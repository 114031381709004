import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {Title} from 'component/global/title';
import CTA from        'component/content/cta';
import {LoaderRipple} from 'component/assets/loader';

import LandingLayout from 'component/layout/page/landing';
import API, {handleError} from 'utils/xhr';



// TODO :: Making edits is ridiculously easy .. and we do it for free .. 
// TODO :: Making edits is ridiculously easy .. and we do it for free .. 
// TODO :: Making edits is ridiculously easy .. and we do it for free .. 
// TODO :: Making edits is ridiculously easy .. and we do it for free .. 
// TODO :: Making edits is ridiculously easy .. and we do it for free .. 

class Page extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            company: null,
            is_loading: false,
        }
    }
    
    componentDidMount(){
        const { company_key } = this.props.match.params;
        if (!company_key) {return;}

        const state = this.state;
        state.is_loading = true;
        this.setState(state);

        const url = 'slickvid/info/' + company_key;
        API.get(url).then(response =>{
            const state = this.state;
            
            const obj = response.data.data;
            if (obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype){
                state.company = null;
            } else {
                state.company = obj;
            }
            state.is_loading = false;
            this.setState(state);
        },(error) =>{
            const state = this.state;
            state.is_loading = false;
            this.setState(state);
            handleError(error, this.props.history, window.location.pathname);
        })
    }
    
    render (){
        const { company_key } = this.props.match.params;
        const is_mobile = window.innerWidth < 640;

        // console.log('this.state.company=', this.state.company);

        return (
            <LandingLayout id="company-page" className="light-bg-1">
                
                <div className={"border white-bg-1 " + ((is_mobile)? ' m-1 py-3 ' : ' m-5 py-3  ')}>


                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="my-3 ">
                                <div className="" style={{display: 'flex', justifyContent: 'center'}} dangerouslySetInnerHTML={{__html: `<SlickVid data-video-id="${company_key}" data-thumbnail="${27}" ${(is_mobile)? `data-size="${Math.min(400, (window.innerWidth-50))}"`: 'data-size="500" '}></SlickVid>`}}/>
                                {/* <div className="my-2 text-center">
                                    <Link to={`/video/${company_key}/export`} className="black-text-3 mx-3"><i className="fas fa-file-export mr-2"></i>Export</Link>
                                    <Link to={`/video/${company_key}/edit`} className="black-text-3 mx-3"><i className="fas fa-edit mr-2"></i>Edit</Link>
                                    <Link to={`/video/${company_key}/analytics`} className="black-text-3 mx-3"><i className="far fa-chart-bar mr-2"></i>Analytics</Link>
                                    <Link to={`/video/${company_key}/copy`} className="black-text-3 mx-3"><i className="fas fa-copy mr-2"></i>Duplicate </Link>
                                </div> */}

                                <div className="mt-3 " style={{display: 'flex', justifyContent: 'center'}}>
                                    <div className="text-size-4 ">Checkout <Link className="secondary-bg-1 white-text-1 px-2 rounded" to="/">SlickVid</Link> for what else is possible with interactive videos.</div>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                    {(this.state.is_loading)? (<LoaderRipple/>) : (

                            <div className="">
                                {(this.state.company !== null)? (
                                <div className="">
                                    <Helmet>
                                        <title>{this.state.company.name || ''}'s gift</title>
                                    </Helmet>

                                    <Title type="strong" underline='primary' className={"text-center black-text-1 " + ((is_mobile)? ' pt-5 mt-5 text-size-10': 'pt-3 text-size-14')} >
                                        A gift for {this.state.company.name}
                                        <img src={this.state.company.logo} alt="" className="ml-2" width='40px' />
                                    </Title>

                                    <div className="my-5 ">

                                        <div className="black-text-3">
                                            <div className="my-3">
                                            This video was made for <span className="strong px-1 ">{this.state.company.name}</span> and shows what's possible with interactive videos.
                                            </div>

                                            <div className="my-3">
                                                You can <a href="mailto:info@bytebeacon.com?subject=SlickVid: Pilot request!" className="secondary-text-1">get started with our pilot</a> to modify this video (it's really easy!) OR You can add this video to your website right now with the following code.
                                            </div>

                                            <div className="my-3 py-3">

                                                <div className="my-1 black-text-7">
                                                    <div className="">
                                                    <pre className={"m-0 py-2 "} style={{fontSize: '0.81em'}}>
                                                        {`<SlickVid data-video-id="${company_key}"></SlickVid>`}
                                                        <br className="" />
                                                        <br className="" />
                                                        {`<script src="https://slickvid.bytebeacon.com/lib/slickvid/0.1.20221201.01_sv.min.js"> </script>`}
                                                    </pre>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="my-3">
                                                <a href="mailto:info@bytebeacon.com?subject=SlickVid: Pilot request!" className="secondary-text-1">Reach out</a> if you'd like to make modifications, need help adding the video to your site or have feedback.
                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>
                                ):(
                                    <div className=''>
                                        <Title type="strong" underline='primary' className={"text-center black-text-1 " + ((is_mobile)? 'text-size-10': 'text-size-14')} >
                                            Video not found!
                                        </Title>

                                        <div className="my-5 text-size-6">
                                            Please check the url.
                                        </div>
                                    </div>
                                )}


                            </div>
                            )}

                        </div>
                    </div>

                </div>

                <CTA />
            </LandingLayout>
        )
    }
}

export default withRouter(Page);
