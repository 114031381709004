import React from 'react';
import { withRouter} from 'react-router-dom';
import {notify} from 'react-notify-toast';

import LandingLayout from 'component/layout/page/landing';


import { LeadsAPI, handleError } from 'utils/xhr';
import { getLeadID } from 'utils/authentication';


class LoginEmail extends React.Component {
  state = {
    form_data: {},
    show_code: false,
  }


  onChange(e){
    const state = this.state;
    state.form_data[e.target.name] = e.target.value;
    this.setState(state);
  }

  runLogin(){
    alert("Invalid code. Please contact info@bytebeacon.com");
  }

  sendEmailCode(e){
    e.preventDefault();

    if (!this.state.form_data || !this.state.form_data.work_email){
      notify.show('Email required!', "custom", 1000, { background: '#ff5b74', text: "#FFFFFF" });
      return;
    }

    const url = '/public/leads';
    const data = {
      'lead_id': getLeadID(),
      'context': {
        'page': 'pilot_login',
        'email': this.state.form_data.work_email,
      }
    }

    LeadsAPI.post(url, data).then(response =>{
      const state = this.state;
      state.show_code = true;
      this.setState(state);
        this.setState(state);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  render (){
    return (
      <LandingLayout id="login-invite">

        <div className="login-form border white-bg-1 rounded p-2">

          <div className="avatar" align="center">
            <span className="fa-stack fa-4x align-items-center">
              <i className="fa fa-circle fa-stack-2x text-secondary"></i>
              <i className="fa fa-at fa-stack-1x fa-inverse"></i>
            </span>
          </div>

          <h2 className="text-center">Email login</h2>

          <div className="m-4">

            <div className="form-group">
                <div className="">
                  {/* <label className="col-form-label mx-2 black-text-3">Please enter your <span className="secondary-text-1 strong">work</span> email address: </label> */}
                  <input
                    type="text"
                    className="form-control"
                    name="work_email"
                    onChange={this.onChange.bind(this)}
                    value={this.state.work_email}
                    placeholder="user@company.com"/>
                </div>
                {(this.state.show_code)? (
                  <div className="">
                    <label className="col-form-label">Invite Code: </label>
                    <input
                      type="text"
                      className="form-control"
                      name="invite_code"
                      onChange={this.onChange.bind(this)}
                      value={this.state.invite_code}
                      placeholder="Invite code from your email"/>
                  </div>
                ): ('')}
              </div>

              <div className="form-group">

                {(this.state.show_code)? (
                  <button
                  onClick={this.runLogin.bind(this)}
                  className="btn btn-primary btn-lg">
                    <span className="">Login <i className="fa fa-sign-in"></i></span>
                  </button>
                ): (
                  <button
                  onClick={this.sendEmailCode.bind(this)}
                  className="mt-3 btn btn-secondary btn-lg white-text-1">
                      <span className="">Generate Login code <i className="fa fa-cog fa-spin fa-lg ml-1"></i></span>
                  </button>
                )}

                <div className="my-3 black-text-6 small">
                  <p className="m-2 ">Trouble logging in? Contact us <a href="mailto:info@bytebeacon.com" className="strong ">here</a></p>
                  <p className="m-2 ">You will not receive emails if your organization is not registered</p>
                </div>

              </div>

          </div>

        </div>



      </LandingLayout>
    )
  }
}

export default withRouter(LoginEmail);
