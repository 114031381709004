import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {Title} from 'component/global/title';
import CTA from        'component/content/cta';
import {LoaderRipple} from 'component/assets/loader';

import LandingLayout from 'component/layout/page/landing';
import API, {handleError} from 'utils/xhr';


class Page extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            blog: null,
            is_loading: false,
        }
    }
    
    componentDidMount(){
        const { blog_key } = this.props.match.params;
        if (!blog_key) {return;}

        const state = this.state;
        state.is_loading = true;
        this.setState(state);

        const url = 'slickvid/info/' + blog_key;
        API.get(url).then(response =>{
            const state = this.state;
            
            const obj = response.data.data;
            if (obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype){
                state.blog = null;
            } else {
                state.blog = obj;
            }
            state.is_loading = false;
            this.setState(state);
        },(error) =>{
            const state = this.state;
            state.is_loading = false;
            this.setState(state);
            handleError(error, this.props.history, window.location.pathname);
        })
    }
    
    render (){
        const { blog_key } = this.props.match.params;
        const is_mobile = window.innerWidth < 640;

        return (
            <LandingLayout id="blog-page" className="light-bg-1">
                
                <div className={"border white-bg-1 " + ((is_mobile)? ' m-1 py-3 ' : ' m-5 py-3  ')}>


                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="my-3 ">
                                <div className="" style={{display: 'flex', justifyContent: 'center'}} dangerouslySetInnerHTML={{__html: `<SlickVid data-video-id="${blog_key}" data-thumbnail="${6}" ${(is_mobile)? `data-size="${Math.min(400, (window.innerWidth-50))}"`: 'data-size="500" '}></SlickVid>`}}/>
                                {/* <div className="my-2 text-center">
                                    <Link to={`/video/${blog_key}/export`} className="black-text-3 mx-3"><i className="fas fa-file-export mr-2"></i>Export</Link>
                                    <Link to={`/video/${blog_key}/analytics`} className="black-text-3 mx-3"><i className="far fa-chart-bar mr-2"></i>Analytics</Link>
                                    <Link to={`/video/${blog_key}/copy`} className="black-text-3 mx-3"><i className="fas fa-copy mr-2"></i>Duplicate </Link>
                                </div> */}

                                <div className="mt-3 " style={{display: 'flex', justifyContent: 'center'}}>
                                    <div className="text-size-4 ">Checkout <Link className="secondary-bg-1 white-text-1 px-2 rounded" to="/">SlickVid</Link> for what else is possible with interactive videos.</div>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                    {(this.state.is_loading)? (<LoaderRipple/>) : (

                            <div className="">
                                {(this.state.blog !== null)? (
                                <div className="">
                                    <Helmet>
                                        <title>{this.state.blog.name || ''}'s video posts</title>
                                    </Helmet>

                                    <Title type="strong" underline='primary' className={"text-center black-text-1 " + ((is_mobile)? ' pt-5 mt-5 text-size-8': 'pt-3 text-size-12')} >
                                        <img src={this.state.blog.logo} alt="" className="mx-2" width='40px' />
                                        {this.state.blog.data.title.text}
                                    </Title>

                                    <div className="my-5 ">

                                        <div className="black-text-3">

                                            <Title type="strong" className={" black-text-1 " + ((is_mobile)? '  ': '  ')} >
                                                
                                                <div className="">
                                                    {this.state.blog.data.body.map(function(item, idx){
                                                        return (<div key={"_"+idx} className="ml-3 ">{item.text}</div>)
                                                    })}
                                                </div>

                                            </Title>

                                        </div>
                                        
                                    </div>
                                </div>
                                ):(
                                    <div className=''>
                                        <Title type="strong" underline='primary' className={"text-center black-text-1 " + ((is_mobile)? 'text-size-10': 'text-size-14')} >
                                            Video not found!
                                        </Title>

                                        <div className="my-5 text-size-6">
                                            Please check the url.
                                        </div>
                                    </div>
                                )}

                            </div>
                            )}

                        </div>
                    </div>

                </div>

                {/* <CTA /> */}
            </LandingLayout>
        )
    }
}

export default withRouter(Page);
