import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import  { Redirect } from 'react-router-dom'

import LandingLayout from 'component/layout/page/landing';
import {Title} from 'component/global/title';
import CTA from        'component/content/cta';



export class UCDetailPage extends React.Component {
    render(){
        const { use_case_key } = this.props.match.params;

        // link: '/use-case/product',
        // link: '/use-case/user-offer',
        // link: '/use-case/user-discount',
        // link: '/use-case/blog-summary',
        // link: '/use-case/company-related',
        // link: '/use-case/announcements',
        // link: '/use-case/recruiting',
        // link: '/use-case/training',
        // link: '/use-case/how-tos',
        // link: '/use-case/engagement',

        if (use_case_key === 'recruiting'){
            return(<UCRecruitingPage />)
        } else if (use_case_key === 'product'){
            return(<UCExplainerPage />)
        } else if (use_case_key === 'blog-summary'){
            return(<UCBlogSummaryPage />)
        } else if (use_case_key === 'user-discount'){
            return(<UCUserDiscountPage />)
        } else if (use_case_key === 'user-offer'){
            return(<UCUserOfferPage />)
        } else if (use_case_key === 'training'){
            return(<UCTrainingPage />)
        } else if (use_case_key === 'company-related'){
            return(<UCCompanyRelatedPage />)
        } else if (use_case_key === 'how-tos'){
            return(<UCHowToPage />)
        } else if (use_case_key === 'engagement'){
            return(<UCEmployeeEngagementPage />)
        } else if (use_case_key === 'announcements'){
            return(<UCAnnouncementsPage />)
        } else {
            // return ''
            return (<Redirect to='/use-case'  />);
        }

    }
}
export default withRouter(UCDetailPage);



class _UCCommonBase extends React.Component {
    render(){
        const is_mobile = window.innerWidth < 640;
        return(
            <LandingLayout id="" className="light-bg-1">
                <div className={"" + ((is_mobile)? 'm-1 ': 'm-5 ')}>
                    <div className="white-bg-1 border rounded">{this.props.children}</div>
                    <CTA />
                </div>
            </LandingLayout>
        )
    }
}

class _HighlightSection extends React.Component {
    render (){
        const sv = this.props.slickvid || {};
        const is_mobile = window.innerWidth < 640;

        return (
            <div className={" " + ((is_mobile)? '': '')}>
                <div className="text-center my-5 ">
                <Title type='page'>When used for <span className="secondary-text-1">{this.props.title}</span></Title>
                </div>
                
                <div className="row d-flex justify-content-center align-middle">
                                            
                    <div className="col-md  " style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5%', }}>
                        <div className="">
                            <div dangerouslySetInnerHTML={{__html: `<SlickVid data-video-id="${sv.videoId}" 
                                                                                            ${(sv.thumb)? `data-thumbnail="${sv.thumb}"`: ''} 
                                                                                            ${(is_mobile)? `data-size="${Math.min(400, (window.innerWidth-50))}"`: 'data-size="500" '}
                                                                                            ${(sv.background)? `data-background-color="${sv.background}"`: ''}></SlickVid>`}}/>
                            
                            <div className="my-3" style={{display: 'flex', justifyContent: 'center'}}>
                                <div className="text-size-4 ">Checkout <Link className="secondary-bg-1 white-text-1 px-2 rounded" to="/">SlickVid</Link> for what else is possible with interactive videos.</div>
                            </div>

                        </div>
                    </div>
                        
                    <div className="col-md "  style={{backgroundColor: this.props.color}}>
                        {this.props.children}
                    </div>

                </div>
            </div>
        )
    }
}



export class UCUserDiscountPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="Discounts 💰 " slickvid={{videoId: 'slickvid_offers', thumb: 2}}>

                    <div className="text-size-6 my-5">
                    Discounts
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCUserOfferPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="Offers 🤝 " slickvid={{videoId: 'slickvid_offers', thumb: 2}}>

                    <div className="text-size-6 my-5">
                    Offers
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCBlogSummaryPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="Blog Summary 📔 " slickvid={{videoId: '20221129_areas_of_discipline', thumb: 13}}>

                    <div className="text-size-6 my-5">
                    Blog summaries
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCExplainerPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="Explainer video 🎬 " slickvid={{videoId: 'slickvid', thumb: 16}}>
                    
                    <div className="text-size-6 my-5">
                    Explainer videos help introduce a concept in a succinct way. 
                    This can be an <span className="secondary-text-1">overview of your product, service, company, policy</span> or anything that can benefit from a soft introduction. 
                    It's an engaging option for people who don't otherwise know about your brand.
                    </div>

                    <div className="text-size-6 my-5">
                        With <span className="secondary-text-1">SlickVid</span> you can build a quick explainer video in minutes. 
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCRecruitingPage extends React.Component {
    render(){
        return(
            <_UCCommonBase >
                <_HighlightSection title="Recruiting 📄 " slickvid={{videoId: 'slickvid_job_posting', thumb: 2}}>
                    
                    <div className="text-size-6 my-5">
                        Grabbing a deserving applicant's attention is hard.
                        Even after standing out, you still get their attention for a very limited time. 
                        You have to <span className="secondary-text-1">put your best foot forward</span>!
                    </div>

                    <div className="text-size-6 my-5">
                        A recruiting video helps present your company the way it deserves to be. 
                        It makes you stand out in a sea of job descriptions, and even the simplest video can say a lot about who you're looking for and why they should join you.
                    </div>

                    <div className="text-size-6 my-5">
                        With <span className="secondary-text-1">SlickVid</span> you can add links to your job posting page right in your videos or go one step further and add a form right at the end of your video to collect candidate leads. 
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCTrainingPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="Employee training 🎉"  slickvid={{videoId: 'slickvid_training', thumb: 2}}>
                    <div className="text-size-6 my-5">
                        With remote working on the rise creating training videos and keeping them upto date is a requierment, not a "nice to have" feature.
                    </div>
                    <div className="text-size-6 my-5">
                        Studies show that the workers of today and tomorrow prefer videos. 
                        They respond better to videos in a corporate setting just as much as they are in their personal lives. 
                    </div>
                    <div className="text-size-6 my-5">
                        These videos can be for <span className="secondary-text-2">onboarding</span>, <span className="secondary-text-2">policy training</span>, <span className="secondary-text-2">common FAQs</span>
                    </div>

                    <div className="text-size-6 my-5">
                        With <span className="secondary-text-1">SlickVid</span> you can build onboarding and training videos quickly amke keeping them updating is much easier than having to recreate traditional videos. 
                    </div>
                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCCompanyRelatedPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="Company Values 💯"  slickvid={{videoId: 'slickvid_company', thumb: 6}}>

                    <div className="text-size-6 my-5">
                        Your company's mission statement can come to life with the help of a <span className="secondary-text-1">SlickVid</span> and make it more meaningful than ever before. 
                    </div>
                    <div className="text-size-6 my-5">
                        Post videos on social media, include it in your formal recruitment, and make it available during onboarding so new employees are reminded of their value at every step of the way.
                    </div>
                    <div className="text-size-6 my-5">
                        With <span className="secondary-text-1">SlickVid</span> you can build one in minutes. 
                        They're usually under 30-seconds long, as the best mission statements are only a few sentences. 
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCEmployeeEngagementPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="Employee Engagement 📈"  slickvid={{videoId: 'slickvid',}}>

                    <div className="text-size-6 my-5">
                    A lot of the times HR chooses to focus on specific areas, instead of creating a broader HR strategy focusing on employee engagement as whole.
                    </div>

                    <div className="text-size-6 my-5">
                    Employee engagement strategies include leadership development, culture, performance, and many different areas of human resources. 
                    And it can be a bit overwhelming to incorporate.
                    A good way to start on this path is by simply generating employee specific content and let it organically grow. 
                    Videos like <span className="secondary-text-1">employee spotlights, public kudos ackonledgments, achievements</span> etc help.

                    </div>
                    <div className="text-size-6 my-5">
                        With <span className="secondary-text-1">SlickVid</span> you can build one in minutes. 
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCAnnouncementsPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="📣 Announcements "  slickvid={{videoId: 'slickvid_announcement', thumb: 2}}>
                    <div className="text-size-6 my-5">

                    Video is a perfect way to communicate what is happening and get people engaged when there's a new initiative.
                    </div>

                    <div className="text-size-6 my-5">
                    Announce platform releases, org changes, or just let the team know about the <i className="family-code">Free Fudge Friday for Friends and Family</i>
                    </div>

                    <div className="text-size-6 my-5">
                    Paint an easy-to-understand picture of new procedures and platforms, or keep everyone up to date with short videos which are better than emails.
                    As a rule of thumb, keep announcement videos short and to the point. Use our template for this and get the word out in lighting speed 🙂
                    </div>

                    <div className="text-size-6 my-5">
                        With <span className="secondary-text-1">SlickVid</span> you can build one in minutes. 
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}
export class UCHowToPage extends React.Component {
    render(){
        return(
            <_UCCommonBase>
                <_HighlightSection title="How to Videos 🗂"  slickvid={{videoId: 'slickvid_how_to', thumb: 2}}>
                    <div className="text-size-6 my-5">
                        Tired of people pinging you on trivial questions.
                    </div>
                    <div className="text-size-6 my-5">
                        Tired of typing up the same answer for folks.
                    </div>
                    <div className="text-size-6 my-5">
                        Want an entertaining way to build a knowledge base?
                    </div>
                    <div className="text-size-6 my-5">
                        Build a <span className="secondary-text-1">SlickVid</span> in less than a minute .. It'll automatically get indexed with your content!
                    </div>
                    <div className="text-size-6 my-5">
                        With <span className="secondary-text-1">SlickVid</span> you can build one in minutes. 
                    </div>

                </_HighlightSection>
            </_UCCommonBase>
        )
    }
}



/*

Celebrating [Linsey Camp]'s year with [COMPANY]
end

/* */