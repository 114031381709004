import React from 'react';
import { withRouter, Link, } from 'react-router-dom';
// NavLink

// import Logo from 'component/assets/logo';
// import {SectionCTA as SubscribeSectionCTA} from 'component/content/subscribe';
// import Announcement from 'component/content/announcement';


class Nav extends React.Component {
  render () {
    const style = {
      background: 'hsl(204, 82%, 55%)',
      background: '-webkit-linear-gradient(to right, hsl(204, 82%, 65%), hsl(204, 82%, 50%))',
      background: 'linear-gradient(to right, hsl(204, 82%, 65%), hsl(204, 82%, 50%))',
    }

    return(
      <section className={' fixed-top' }>
      {/* <Announcement /> */}
      <nav className={"navbar navbar-expand-lg white-bg-1 shadow p-0"} >

        <div className="navbar-header">
          <Link to="/" className="navbar-brand text-spacing-2 mr-2 text-size-10">
            <span style={{verticalAlign: 'middle', }} className="black-text-3 white-bg-1 rounded mx-1 px-1">SlickVid</span>
          </Link>  
        </div>

        <button className="navbar-toggler btn btn-primary black-text-1 mr-2" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">

          <ul className="navbar-nav mr-auto">
            <li className="nav-item active"></li>
          </ul>

          <ul className="navbar-nav " style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
          <li className="nav-item p-2 mx-2 ">
              <Link to="#" className="black-text-1 fancyline"><i className="mr-2 fas fa-tachometer-alt"></i>Dashboard</Link>
            </li>
            <li className="nav-item p-2 mx-2 ">
              <Link to="#" className="black-text-1 fancyline"><i className="mr-2 fas fa-search"></i>Browse</Link>
            </li>
            <li className="nav-item p-2 mx-2 ">
              <Link to="#" className="black-text-1 fancyline"><i className="mr-2 far fa-file-video"></i>Templates</Link>
            </li>
            <li className="nav-item p-2 mx-2 ">
              <Link to="#" className="black-text-1 fancyline"><i className="mr-2 fas fa-video"></i>Videos</Link>
            </li>
            <li className="nav-item p-2 mx-2 ">
              <Link to="#" className=" rounded border-primary white-bg-1 black-text-8 " title="Logout"><i className="fa fa-sign-out-alt mr-2"></i></Link>
            </li>
            
          </ul>

        </div>
      </nav>
      </section>
    )
  }
}


export default withRouter(Nav);
