import React from 'react';
import { withRouter} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import LandingLayout from 'component/layout/page/landing';

import USP from            'component/content/usp';
import CTA from        'component/content/cta';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="landing-page" className="dark-bg-1">
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>
  
        <div className="content-section " style={{backgroundImage: `url('/static/images/bg/07.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} > 
          <div className="" style={{backdropFilter: 'blur(10px)'}}>
            <USP />
          </div>
        </div>

        <div className="white-bg-1 py-5 ">
          <CTA />
        </div>

        {/* <Testimonials /> */}

        {/* <div 
          className="content-section"    
          style={{backgroundImage: `url('/static/images/bg/06.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
        >
          <SubscribePageCTA category="enterprise" inverted />
        </div> */}


      </LandingLayout>
    )
  }
}

export default withRouter(Page);
