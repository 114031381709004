import React from 'react';

// import {FooterLink, FooterExtLink} from './base';
import {AboutComponent, UseCasesComponent, CopyrightFooter, CompanyComponent, OtherProductsComponent, ReachComponent} from './components';



export function LandingFooter(props){
  const is_mobile = window.innerWidth < 1200;
  return(
    <footer>
      <div  className="p-1 pb-3 dark-bg-1 shadow text-black-9">
        <div className="row m-0">
          <div className="col-sm-3 px-1"><AboutComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-1"><UseCasesComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-1"><CompanyComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-1"><OtherProductsComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-3 px-1"><ReachComponent is_mobile={is_mobile} /></div>
        </div>
      </div>
      <CopyrightFooter padding={5} />
    </footer>
  )
}

export function SignedInFooter(props){
  const is_mobile = window.innerWidth < 1200;
  return(
    <footer>
      <CopyrightFooter padding={1} />
    </footer>
  )
}

