import React from 'react';
import { withRouter, Link, } from 'react-router-dom';
import Logo from 'component/assets/logo';
// NavLink

// import Logo from 'component/assets/logo';
// import {SectionCTA as SubscribeSectionCTA} from 'component/content/subscribe';
// import Announcement from 'component/content/announcement';


class Nav extends React.Component {
  render () {

    const is_mobile = window.innerWidth < 640;

    return(
      <section >
      <div className="header shadow">

        {(is_mobile)? (
          <Link to="/" className="">
            <span className="ml-2 black-text-3"><Logo name="logo" size={45} /></span>
          </Link>
        ): (
          <div className="navbar-header">
            <Link to="/" className="navbar-brand text-spacing-2 mr-2 text-size-10">
              <span style={{verticalAlign: 'middle', }} className="black-text-3 white-bg-1 rounded ">Slick</span>
              <span style={{verticalAlign: 'middle', borderRadius: '20px'}} className="primary-bg-1 white-text-1 px-2 ">Vid</span>
            </Link>  
          </div>
          )}



        <nav className="navigation" style={{alignItems: 'right'}}>
          {/* <li className="navigation__item"><Link to="/" className="navigation__link">Home</Link></li> */}
          {/* <ProfileNavSection /> */}
          <UseCaseNavSection />
          {/* <li className="navigation__item"><Link to="/use-case" className="navigation__link">Use Cases</Link></li> */}
          <li className="navigation__item"><Link to="/features" className="navigation__link">Features</Link></li>
          <li className="navigation__item"><Link to="/comparison" className="navigation__link">Comparison</Link></li>
        </nav>
      </div>

      </section>
    )
  }
}

function UseCaseNavSection(props){
  const is_mobile = window.innerWidth < 640;
  return (
    <li className="navigation__item navigation__item--has-submenu">
      <Link to="/use-case" className="navigation__link"> Use Cases </Link>
      <div className="submenu">
        <div className="submenu__content">
          <div className="submenu__box">
            <ul className="submenu-navigation">
  
              <li className="submenu-navigation__item">
                <Link to="/use-case/user-discount" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">Discounts and Offers</span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>Personalized discounts, next purchase trigger, VIP offers, important moments, reactivate discounts </span>
                </Link>
              </li>
              <li className="submenu-navigation__item">
                <Link to="/use-case/product" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">Product videos<span className="label mx-2">popular</span></span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>Explainer video, teasers, feature launch etc ... </span>
                </Link>
              </li>
              <li className="submenu-navigation__item">
                <Link to="/use-case/company-related" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">Company videos</span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>Videos for Company values, mission, policies, annual reports, monthly updates etc ... </span>
                </Link>
              </li>
              <li className="submenu-navigation__item">
                <Link to="/use-case/blog-summary" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">Blog summaries</span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>Summarize your blogs into interactive videos ending wih a lead gen CTA  </span>
                </Link>
              </li>
              {/* <li className="submenu-navigation__item">
                <Link to="/use-case/training" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">Employee Training</span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>Onboarding 101, How to contact helpdesk, soft skills training etc </span>
                </Link>
              </li> */}
              {/* <li className="submenu-navigation__item">
                <Link to="/use-case/how-tos" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">How Tos</span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>How to contact the HR team, file an expense, submit leave application etc </span>
                </Link>
              </li> */}
            </ul>
          </div>
          {/* <div className="submenu__box">
            <img className="submenu__image" src="https://images.unsplash.com/photo-1661423063753-ea9121c6abb2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3840&q=80" />
          </div> */}
        </div>
        <div className="submenu__footer">
          <Link to="/use-case" className="submenu__link">
            View all
            <span className="submenu__icon" ><i className="fas fa-arrow-right"></i></span>
          </Link>
        </div>
      </div>
    </li>
  )
}

function ProfileNavSection(props){
  const is_mobile = window.innerWidth < 640;
  return (
    <li className="navigation__item navigation__item--has-submenu">
      <Link to="/profile" className="navigation__link"> Profile </Link>
      <div className="submenu">
        <div className="submenu__content">
          <div className="submenu__box">
            <ul className="submenu-navigation">
              <li className="submenu-navigation__item">
                <Link to="/profile/hr" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">HR</span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>See how your HR team can make use of interactive videos</span>
                </Link>
              </li>
              <li className="submenu-navigation__item">
                <Link to="/profile/hr" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">Marketing <span className="label mx-2">popular</span></span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>Enjoy our Product Two Enjoy our Product Two Enjoy our Product Two Enjoy our Product Two</span>
                </Link>
              </li>
              <li className="submenu-navigation__item">
                <Link to="/profile/hr" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">Product</span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>Enjoy our Product Three</span>
                </Link>
              </li>
              <li className="submenu-navigation__item">
                <Link to="/profile/hr" className="submenu-navigation__link">
                  <span className="submenu-navigation__title">Sales</span>
                  <span className="submenu-navigation__subtitle black-text-5"  style={{fontSize: '0.75em',}}>Enjoy our Product Four</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="submenu__box">
            <img className="submenu__image" src="https://images.unsplash.com/photo-1661423063753-ea9121c6abb2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3840&q=80" />
          </div> */}
        </div>
        <div className="submenu__footer">
          <Link to="/profile" className="submenu__link">
            See more
            <span className="submenu__icon" ><i className="fas fa-arrow-right"></i></span>
          </Link>
        </div>
      </div>
    </li>
  )
}


export default withRouter(Nav);
