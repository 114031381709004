import React from 'react';


export function Loader(props){
  return (<LoaderRings />)
}

export function LoaderRings(props){
  return (<img src="/images/loaders/rings.svg" alt="Loader"/>)
}

export function LoaderPulse(props){
  return (<img src="/images/loaders/pulse.svg" alt="Loader"/>)
}

export function LoaderWedge(props){
  return (<img src="/images/loaders/wedge.svg" alt="Loader"/>)
}

export function LoaderRipple(props){
  return (<img src="/images/loaders/ripple.svg" alt="Loader"/>)
}
