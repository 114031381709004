import React from 'react';

import PageBlankLayout from 'component/layout/page/blank';

import Nav from 'component/layout/navigation/signed_in';
import {SignedInFooter} from 'component/layout/footer';


export default function ContentLayout(props) {
  return (
    <PageBlankLayout className={props.className }>

      <Nav />

      <div className="page-content my-5">
        {props.children}
      </div>

      <SignedInFooter />
    </PageBlankLayout>
  );
}
