import React from "react";

import Slide from 'react-reveal/Slide';


export function SectionTitle(props){
  return (
    <div className="">
      <h1 className="family-h1 text-center py-3">
        <span className={""}>{props.first}</span> 
        <span className={""}>{" " + props.second}</span>
      </h1>
    </div>
  )
}


export function ParaTitle(props){
  var bgclass = (props.background)? props.background: 'secondary';
  bgclass = "text-" + bgclass;
  
  const firstbg = (props.flip)? "text-black-3": bgclass;
  const secondbg = (props.flip)? bgclass: "text-black-3";

  return (
    <Slide bottom>
      <h4 className="family-h5 text-center uppercase text-spacing-2 m-0">
        <span className={firstbg}>{props.first}</span>
        <span className={secondbg}>{props.second}</span>
      </h4>
    </Slide>
  )
}


export function Title (props){
  const type = props.type;
  const underline = props.underline || null;
  let className = props.className;
  const is_mobile = window.innerWidth < 640;

  if (type === 'page'){
    className = className + ("  text-wide-3 " + ((is_mobile)? ' text-size-10 ': ' text-size-15 '));
  } else if (type === 'section'){
    className = className + ("  text-wide-1 " + ((is_mobile)? ' text-size-8 ': ' text-size-11 '));
  } else if (type === 'strong'){
    className = className + ("  text-wide-1 " + ((is_mobile)? ' text-size-6 ': ' text-size-8 '));
  } else {
    return <p>{props.children}</p>
  }

  const underlineMargin = {
    'right': `0 20% 0 auto`,
    'left': `0 auto 0 20%`,
    'center': `0 auto 0 auto`,
  };
  return (
    <React.Fragment>
      <div className={className}>{props.children}</div>
      {(underline)? (
        <div className={`${underline}-bb-5-5 rounded`} style={{padding: `40px 0 0 0`, width:`220px`, lineHeight: `1.6em`, margin: underlineMargin['center']}}>
        </div>
      ): ('')}
    </React.Fragment>

  )
}
