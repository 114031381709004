import React from 'react';
import {Link} from 'react-router-dom';

import Logo from 'component/assets/logo';
import SocialComponent from 'component/global/social';
import { TEXT, PROFILE } from "component/global/constants";


import { FooterLink, FooterExtLink, FooterSectionTitle} from './base';



export function AboutComponent(props){
  return (
    <div className="px-3">
      <div className="m-1">        
        <div className="mt-5 mb-2 text-center">
          <Link to="/" className="text-spacing-2 mr-2 text-size-12">
            <span style={{}} className={"mx-2 " + TEXT.read_theme}><Logo name="logo" size={50} /></span>
            <span style={{verticalAlign: 'middle', }} className={"mr-1 " + TEXT.muted_theme}>Slick</span>
            <span style={{verticalAlign: 'middle', }} className={" " + TEXT.read_theme}>Vid</span>
          </Link>  
        </div>
        <div className={" family-code " + TEXT.muted}>
          <p className="" style={{textAlign:'justify'}}>
            Fast way of converting your messaging into highly engaging and interactive videos.
          </p>
        </div>
      </div>
    </div>
  )
}


export function CopyrightFooter(props){
  return (
    <div className={" family-code bg-dark text-center py-" + props.padding}>
      <div className={"" + TEXT.muted}>© { new Date().getFullYear() } - <a className=" text-spacing-2 white-text-1" href="https://bytebeacon.com">ByteBeacon, Inc</a> - All rights reserved.</div>
    </div>
  )
}


export function UseCasesComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className={" text-center " + ((is_mobile)? ' ': " m-1")}>
      <FooterSectionTitle title="Use Cases"/>
      <div className="group-vertical">
        <FooterLink to="/use-case/company-related" text="Company" />
        <FooterLink to="/use-case/recruiting" text="Recruting" />
        <FooterLink to="/use-case/training" text="Training" />
        <FooterLink to="/use-case/how-tos" text="How tos" />
        <FooterLink to="/use-case/announcements" text="Announcements" />
        {/* <FooterLink to="/use-case/engagement" text="Employee Engagement" /> */}
        <FooterLink to="/use-case/product" text="Product" />
      </div>
    </div>
  )
}


export function CompanyComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className={" text-center " + ((is_mobile)? ' ': " m-1")}>
      <FooterSectionTitle title="Company"/>
      <div className="group-vertical">
        <FooterExtLink href="https://bytebeacon.com" text="Home" />
        <FooterExtLink href="https://blog.bytebeacon.com" text="Blog" />
        <FooterExtLink href="https://bytebeacon.com/legal/privacy" text="Privacy" />
        <FooterExtLink href="https://bytebeacon.com/legal/tos" text="Terms" />
        <FooterExtLink href="https://angel.co/company/bytebeacon/jobs" text="Jobs" />
      </div>
    </div>
  )
}

export function OtherProductsComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className={" text-center " + ((is_mobile)? ' ': " m-1")}>
      <FooterSectionTitle title="products"/>
      <div className="">
        <FooterExtLink href="https://lightflare.bytebeacon.com" text="LightFlare" />
        <FooterExtLink href="https://bytetrack.bytebeacon.com" text="ByteTrack" />
        <FooterExtLink href="https://navigator.bytebeacon.com" text="Navigator" />
        <FooterExtLink href="https://divshow.bytebeacon.com" text="DivShow" />
      </div>
    </div>
  )
}


export function ReachComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className="text-center">
      {(is_mobile)? (<hr className="highlight-white-4 "/>) : ('')}

      <div className="my-2">
        <FooterSectionTitle title="Follow"/>
        <div className="group-vertical my-1 text-size-7 ">
        <SocialComponent contact={PROFILE.contact} />
        </div>
      </div>

      <hr className="highlight-white-4 white-bb-1-1 mx-5"/>

      <div className="my-2">
        <FooterSectionTitle title="Contact"/>
        <div className="group-vertical my-1 text-size-7 ">
          <a className={"mx-2 black-text-3"} href="mailto:info@bytebeacon.com"><i className="fa fa-envelope"></i></a>
          <a className={"mx-2 black-text-3"} href="https://join.slack.com/t/bytebeacon/shared_invite/enQtODAxNTUwODM0MjU4LTU3NDFiMGYzMDgyMDY0OWM3YzAzOWMyODlhYjM4NGY4Zjc1N2U0MWM0N2UyMGVkNTIyY2IwZjkxMGM3ZDRmY2E"><i className="fab fa-slack-hash"></i></a>
          <a className={"mx-2 black-text-3"} href="https://angel.co/company/bytebeacon"><i className="fab fa-angellist"></i></a>
        </div>
      </div>

    </div>
  )
}
