import React from 'react';

import PageBlankLayout from 'component/layout/page/blank';
import {LandingFooter} from 'component/layout/footer';
// import Nav from 'component/layout/navigation/topbar';
import Nav from 'component/layout/navigation/enterprise';
import SEOComponent from 'component/global/seo';


export default function LandingLayout(props) {
  const context = props.context || {};

  return (
    <PageBlankLayout>

      <SEOComponent seo={context.seo}/>

      <Nav />

      <div className={"landing-content black-text-3 " + ((props.className)? props.className: '') }>
        {props.children}
      </div>

      <LandingFooter />
    </PageBlankLayout>
  );
}
