import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import {Title} from 'component/global/title';

import Layout from 'component/layout/page/signed_in';



class Page extends React.Component {
  render (){

    const templates = [
      {title: "Company Values", link: '/templates/company-related',hue: 10,},
      {title: "Company Mission", link: '/templates/company-related',hue: 10,},
      {title: "Company: Policy update", link: '/templates/company-related',hue: 10,},
      {title: "Annual report", link: '/templates/company-related',hue: 10,},
      {title: "Monthly update", link: '/templates/company-related',hue: 10,},
      {title: "Announcement: Launch", link: '/templates/announcements',hue: 10,},
      {title: "Announcement: CXO updates", link: '/templates/announcements',hue: 10,},
      {title: "Announcement: holidays", link: '/templates/announcements',hue: 10,},
      {title: "Job description", link: '/templates/recruiting',hue: 250,},
      {title: "Team info", link: '/templates/recruiting',hue: 250,},
      {title: "Product: Explainer", link: '/templates/product',hue: 200,},
      {title: "Product: Teaser", link: '/templates/product',hue: 200,},
      {title: "Product: Feature launch", link: '/templates/product',hue: 200,},
      {title: "Product: Feature highlight", link: '/templates/product',hue: 200,},
      {title: "Training: 101", link: '/templates/training',hue: 300,},
      {title: "Training: Soft skill", link: '/templates/training',hue: 300,},
      {title: "How to: internal", link: '/templates/how-tos',hue: 300,},
      {title: "How to: external", link: '/templates/how-tos',hue: 300,},
      {title: "Employee Spotlight", link: '/templates/engagement',hue: 150,},
      {title: "Kudos", link: '/templates/engagement',hue: 150,},
      {title: "Achievements", link: '/templates/engagement',hue: 50,},
      {title: "Anniversary", link: '/templates/engagement',hue: 50,},
    ]

    const is_mobile = window.innerWidth < 640;
    const { company_key } = this.props.match.params;

    return (
      <Layout id="templates-page" className="light-bg-1">
        
        <div className={"border rounded white-bg-1 "  + ((is_mobile)? ' m-2 p-2 ' : ' my-5 py-5 px-2 ')}>
            <Title type="section" underline='primary' className={"text-center black-text-3 "} >
                Pick a template:
            </Title>

            <div className="my-5 mx-1 ">

                <div className="" style={{ position: 'relative', display: 'grid', gap: '1px', gridTemplateColumns: ((is_mobile)? 'repeat(1, 1fr)' : 'repeat(6, 1fr)'),}}>
                    {templates.map(function(template, idx){return (<Section key={`_${idx}`}  item={template} idx={idx} />)})}
                </div>

            </div>

        </div>

      </Layout>
    )
  }
}



class Section extends React.Component {
  render(){
      const is_mobile = window.innerWidth < 640;
      var  item = this.props.item;
      const idx = this.props.idx;
      // item.hue = idx * 50;
      return (
          <div className="  shadow border m-2 p-1  " style={{position: 'relative',  overflow: 'hidden', borderRadius: '5px', backgroundColor: `hsl(${item.hue}, 100%, 100%)`}}>

          <div className="">

              <div className="m-2 " style={{textAlign: ''}}>
                  <Link className=" black-text-1" to={'#'} >
                      <div className="text-size-3">{item.title}</div>                      
                      <div className="my-3 ml-2 " style={{height: '3px', left: '0px', width: '36px', backgroundColor: `hsl(${item.hue}, 99%, 68%)`}}></div>
                  </Link>
              </div>
          </div>

        </div>
      )
  }
}
export default withRouter(Page);
