import React from 'react';
import { withRouter, Link} from 'react-router-dom';

import LandingLayout from 'component/layout/page/landing';

  
  
class LoginPage extends React.Component {
    
  render (){
    const is_mobile = window.innerWidth < 1200;
    return (
      <LandingLayout id="login-page">

        <div className="flex-center py-5">
          
          <div className={"login-form border white-bg-1 rounded p-2 " + ((is_mobile)? 'py-5': '')}>

            <div className="avatar" align="center">
              <span className="fa-stack fa-4x align-items-center">
                <i className="fa fa-circle fa-stack-2x secondary-text-2"></i>
                <i className="fa fa-user fa-stack-1x fa-inverse"></i>
              </span>
            </div>

            {/* <h5 className="text-center my-3">Currently open for pilot users!</h5> */}

            <div className="m-4">
              <div className="m-2 text-center">
                <Link to="/login/email" className="btn btn-lg btn-block btn-secondary"><i className="fa fa-envelope mr-2"></i>Login with E-Mail</Link>
              </div>

              <div className="p-2 lead small black-text-6">
                <p className="m-0">Currently open for pilot users only. </p>
                <p className="m-0"><a href="mailto:info@bytebeacon.com">Contact us</a> if interested in joining the pilot.</p>
              </div>

            </div>
          </div>

        </div>
      </LandingLayout>
    )
  }
}


export default withRouter(LoginPage);
