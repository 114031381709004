import React from 'react';

export default class Blogs extends React.Component {

  render (){
    const blogs = [
      // {text: "In 2018, people watched an average of 1.5 hours per day of online video. For 2022, survey shows this has risen to an average of over 2.5 hours per day."},
      {text: "Most brands get new customers due to engaging videos.", link: "https://www.hubspot.com/marketing-statistics"},
      {text: "Majority of companies say that video content is a major part of their marketing plan."},
      {text: "As of 2022, an average person is predicted to spend 100 minutes per day watching online videos", link: "https://invideo.io/blog/video-marketing-statistics/"},
      {text: "The vast majority say that videos have better numbers regarding ROI than simple static images."},
      {text: "Most video marketers claimed that they had generated leads with the help of video content.", link: "https://www.hubspot.com/marketing-statistics"},
      {text: "Video increase organic traffic from search engine results pages", link: "https://www.brightcove.com/en/resources/blog/create-compelling-video-experiences/"},
      {text: "Majority of people prefer watching a short video to learn about a product or service.", link: "https://elitecontentmarketer.com/video-marketing-statistics/"},
      {text: "Video content is farm more successful than other content. Viewers absorb more of the message while watching the video."},
    ];

    const blog = blogs[parseInt((Math.random()* blogs.length))];
    return (
      <section id="blogs">
        <div className=" my-3 rounded py-3">
          <div className="container text-center ">
            <p className="white-text-1 text-size-10">{blog.text}</p>
            {(blog.link)? (
              <div className="pt-5"><a href={blog.link} className="white-bg-1 py-2 px-3 rounded text-size-7 black-text-1">Source <i className="ml-2 fa fa-arrow-right"></i></a></div>
            ): ('')}
          </div>
        </div>
      </section>
    )
  }
}