import React from 'react';
import { Link, withRouter} from 'react-router-dom';

import LandingLayout from 'component/layout/page/landing';
import {Title} from 'component/global/title';
import CTA from        'component/content/cta';


class Page extends React.Component {
  render (){

    const cases = [
        {
            title: "Product", 
            link: '/use-case/product',
            desc: "Explainer video, teasers, feature launch etc ... ", 
            icon: "fas fa-wrench",
            hue: 200,
        },
        {
            title: "User offer", 
            link: '/use-case/user-offer',
            desc: "Customized offers to send to your users", 
            icon: "fas fa-coffee",
            hue: 50,
        },
        {
            title: "User discount", 
            link: '/use-case/user-discount',
            desc: "Customized discounts for your users. Personalize content as it goes out!", 
            icon: "fas fa-tag",
            hue: 50,
        },
        {
            title: "Blog Summaries", 
            link: '/use-case/blog-summary',
            desc: "Condense blog posts into bite sized videos and add CTA at the end", 
            icon: "fas fa-blog",
            hue: 150,
        },
        {
            title: "Company ", 
            link: '/use-case/company-related',
            desc: "Videos for Company values, mission, policies, annual reports, monthly updates etc ... ", 
            icon: "far fa-building",
            hue: 10,
        },
        {
            title: "Announcements", 
            link: '/use-case/announcements',
            desc: "New feature launch, CXO updates, holiday announcements etc ... ", 
            icon: "fas fa-random",
            hue: 10,
        },
        {
            title: "Recruiting", 
            link: '/use-case/recruiting',
            desc: "Job description, team details, benefits etc ... ", 
            icon: "fas fa-user",
            hue: 250,
        },
        {
            title: "Employee Training", 
            link: '/use-case/training',
            desc: "Onboarding 101, How to contact helpdesk, soft skills training etc ", 
            icon: "far fa-id-badge",
            hue: 300,
        },
        {
            title: "How to", 
            link: '/use-case/how-tos',
            desc: "How to contact the HR team, file an expense, submit leave application etc ", 
            icon: "fas fa-info",
            hue: 300,
        },
        // {
        //     title: "Employee Engagement", 
        //     link: '/use-case/engagement',
        //     desc: "Employee spotlight videos, Share kudos, achievements etc ", 
        //     icon: "far fa-id-badge",
        //     hue: 200,
        // },
    ]

    const is_mobile = window.innerWidth < 640;
    return (
      <LandingLayout id="" className=" light-bg-1 ">
          <div className={"border  rounded white-bg-1 "  + ((is_mobile)? ' m-2 p-2 ' : ' m-5 p-5 ')}>
            {/* <Title type='page'><span className="">💡</span> Use cases</Title> */}
            <Title type="section" underline='primary' className={"text-center black-text-3 " + ((is_mobile)? 'text-size-10': 'text-size-14')} >
                How can you use <span className="primary-text-1">SlickVid</span>
            </Title>

            <div className="my-5 mx-2 ">

                <div className={" mt-3 mb-5 " + ((is_mobile)? ' text-size-5 ': ' text-size-8 ')}>
                    There are <span className="primary-text-1 strong">endless possibilities</span>, but here are a few examples to get you inspired!
                </div>

                <div className="" style={{ position: 'relative', display: 'grid', gap: '1px', gridTemplateColumns: ((is_mobile)? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'),}}>
                    {cases.map(function(usecase, idx){return (<Section key={`_${idx}`}  item={usecase} idx={idx} />)})}
                </div>

            </div>

        </div>
            {/* <hr className="" /> */}
            <CTA />
            
  </LandingLayout>
    )
  }
}


class Section extends React.Component {
    render(){
        const is_mobile = window.innerWidth < 640;
        var  item = this.props.item;
        const idx = this.props.idx;
        // item.hue = idx * 50;
        return (
            <div className="  shadow  m-2 p-3  " style={{position: 'relative',  overflow: 'hidden', borderRadius: '15px', backgroundColor: `hsl(${item.hue}, 100%, 100%)`}}>

            <div className="">
                {/* <div className="" style={{textAlign: ((is_mobile)? 'right': 'center')}}>
                    <span className="fa-stack fa-2x align-items-center">
                        <i className="fa fa-circle fa-stack-2x " style={{color: `hsl(${item.hue}, 100%, 98%)`}}></i>
                        <i className={" fa-stack-1x  " + item.icon} style={{color: `hsl(${item.hue}, 90%, 48%)`}}></i>
                    </span>
                </div> */}
                <div className="m-2 " style={{textAlign: ''}}>
                    <Link className=" black-text-1" to={''+item.link} >
                    <Title type="strong"  className={"text-size-8"} >
                        <div className=""style={{ }}>
                        {item.title}
                        </div>
                        <div className="my-3 ml-2 " style={{height: '3px', left: '0px', width: '36px', backgroundColor: `hsl(${item.hue}, 99%, 68%)`}}></div>
                    </Title>
                    </Link>
                </div>
            </div>
  
            <span className="fa-stack fa-2x align-items-center" style={{position: 'absolute', top: '5%', right: '2%'}}>
                <i className="fa fa-circle fa-stack-2x " style={{color: `hsl(${item.hue}, 100%, 98%)`}}></i>
                <i className={" fa-stack-1x  " + item.icon} style={{color: `hsl(${item.hue}, 90%, 48%)`}}></i>
            </span>


            <div className="m-2 black-text-5 ">
                {item.desc}
            </div>
  
          </div>
        )
    }
}

export default withRouter(Page);
