import './video_edit.css';

import React from 'react';
import { withRouter} from 'react-router-dom';

import {toHex} from 'color2k';

import Layout from 'component/layout/page/signed_in';


class Page extends React.Component {

  render (){
    const api_resp = {
      "data": {
        "config": {
          "audio": {
            "url": "https://link.storjshare.io/raw/jurrdm6uqjpsygnrezizb332gsha/slickvid-music%2Fmusic%2Fdrop-the-beat-all-good-folks-main-version-17517-00-55.mp3"
          }, 
          "color": {
            "background": null, 
            "text": "hsl(234, 59%, 60%)"
          }, 
          "end": {
            "cta_url": null, 
            "form": "subscription"
          }, 
          "font": {
            "body": {
              "name": "Montserrat", 
              "weight": ""
            }, 
            "emphasized": {
              "name": "Baloo", 
              "weight": ""
            }, 
            "size": 16.0
          }, 
          "size": {
            "height": 400, 
            "width": 400
          }, 
          "social": {
            "facebook": "https://facebook.com/bytebeacon", 
            "github": null, 
            "instagram": null, 
            "linkedin": "https://www.linkedin.com/company/bytebeacon/", 
            "twitter": "https://twitter.com/bytebeacon"
          }, 
          "start": {
            "thumb_position": null
          }, 
          "theme": "light"
        }, 
        "scenes": [
          {
            "content": {
              "sentence": [
                {
                  "text": "This video is "
                }, 
                {
                  "emphasized": true, 
                  "text": "interactive"
                }
              ]
            }, 
            "context": {
              "background_type": "", 
              "duration": 5, 
              "text_type": ""
            }, 
            "scene_key": "emphasizeTextSentence"
          }, 
          {
            "content": {
              "sentence": [
                {"text": "and took "}, 
                {"emphasized": true, "text": "<5 mins"}, 
                {"text": "to make"}
              ]
            }, 
            "context": {
              "background_type": "arrow", 
              "duration": 5, 
              "text_type": "float"
            }, 
            "scene_key": "emphasizeTextSentence"
          }, 
          {
            "content": {
              "text": "Just by filling a form!"
            }, 
            "context": {
              "background_type": "bubbles", 
              "duration": 5, 
              "text_type": ""
            }, 
            "scene_key": "dropWord"
          }, 
          {
            "content": {
              "home_url": "https://slickvid.bytebeacon.com", 
              "logo_url": "https://slickvid.bytebeacon.com/logo.svg", 
              "name": "SlickVid", 
              "text": "Introducing"
            }, 
            "context": {
              "background_type": "slides", 
              "duration": 5, 
              "text_type": ""
            }, 
            "scene_key": "logoPop"
          }, 
          {
            "content": {
              "sections": [
                {
                  "svg_src": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" className=\"bi bi-film\" viewBox=\"0 0 16 16\"><path d=\"M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z\"/></svg>", 
                  "text": "Product intro", 
                  "url": "https://slickvid.bytebeacon.com/use-case/product"
                }, 
                {
                  "svg_src": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" className=\"bi bi-brightness-high\" viewBox=\"0 0 16 16\"><path d=\"M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z\"/></svg>", 
                  "text": "Company values", 
                  "url": "https://slickvid.bytebeacon.com/use-case/company-related"
                }, 
                {
                  "svg_src": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" className=\"bi bi-card-text\" viewBox=\"0 0 16 16\"><path d=\"M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z\"/><path d=\"M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z\"/></svg>", 
                  "text": "Company report", 
                  "url": ""
                }, 
                {
                  "svg_src": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" className=\"bi bi-hand-thumbs-up\" viewBox=\"0 0 16 16\"><path d=\"M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z\"/></svg>", 
                  "text": "Employee spotlight", 
                  "url": ""
                }, 
                {
                  "svg_src": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" className=\"bi bi-megaphone\" viewBox=\"0 0 16 16\"><path d=\"M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z\"/></svg>", 
                  "text": "Announcement", 
                  "url": "https://slickvid.bytebeacon.com/use-case/announcements"
                }, 
                {
                  "svg_src": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" className=\"bi bi-person-vcard-fill\" viewBox=\"0 0 16 16\"><path d=\"M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z\"/></svg>", 
                  "text": "Employee onboarding", 
                  "url": "https://slickvid.bytebeacon.com/use-case/training"
                }, 
                {
                  "svg_src": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" className=\"bi bi-people\" viewBox=\"0 0 16 16\"><path d=\"M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z\"/></svg>", 
                  "text": "Meet the team", 
                  "url": ""
                }, 
                {
                  "svg_src": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" className=\"bi bi-file-earmark-post-fill\" viewBox=\"0 0 16 16\"><path d=\"M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1 0-1zm0 3h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z\"/></svg>", 
                  "text": "Job posting", 
                  "url": "https://slickvid.bytebeacon.com/use-case/recruiting"
                }
              ], 
              "subtitle": "Or create your own ...", 
              "title": "Step 1: Pick a template"
            }, 
            "context": {
              "background_type": "", 
              "duration": 8, 
              "text_type": ""
            }, 
            "scene_key": "titleMultiTextHighlight"
          }, 
          {
            "content": {
              "img_src": "https://slickvid.bytebeacon.com/static/slickvid/product/20221112_fill_form.gif", 
              "subtext": "Focus on your content", 
              "text": "Step 2: Fill the template form!", 
              "url": "https://slickvid.bytebeacon.com"
            }, 
            "context": {
              "duration": 8
            }, 
            "scene_key": "imgGlide"
          }, 
          {
            "content": {
              "img_src": "https://slickvid.bytebeacon.com/static/slickvid/product/20221116_slickvid.gif", 
              "subtext": "Extremely easy integration", 
              "text": "Step 3: Get your SlickVid out!", 
              "url": "https://slickvid.bytebeacon.com"
            }, 
            "context": {
              "duration": 6
            }, 
            "scene_key": "imgGlide"
          }, 
          {
            "content": {
              "text": "it's that easy"
            }, 
            "context": {
              "duration": 4
            }, 
            "scene_key": "fastWords"
          }, 
          {
            "content": {
              "home_url": "https://slickvid.bytebeacon.com", 
              "logo_url": "https://slickvid.bytebeacon.com/logo.svg", 
              "name": "SlickVid", 
              "text": "Checkout ..."
            }, 
            "context": {
              "background_type": "", 
              "duration": 5, 
              "text_type": ""
            }, 
            "scene_key": "logoPop"
          }, 
          {
            "content": {}, 
            "context": {
              "duration": 3
            }, 
            "scene_key": "circleRipple"
          }, 
          {
            "content": {}, 
            "context": {
              "duration": 2
            }, 
            "scene_key": "simpleSubsciptionBranding"
          }
        ]
      }, 
      "meta": {
        "generated_at_utc": "Mon, 28 Nov 2022 10:36:38 GMT", 
        "request_id": "2d212c59-68bd-4eaf-8f12-289ba1915fa3", 
        "success": true
      }
    }

    const { company_key, video_id } = this.props.match.params;
    return (
      <Layout id="video-edit-page">

        <div className="row">
          <div className="col">
            <SampleForm company_key={company_key} video_id={video_id} data={api_resp.data} />
          </div>
          <div className="col">

            <div className="py-5 my-5 sticky-top">
              <h3 className="my-3">Render:</h3>
              
              <div className="my-3 " dangerouslySetInnerHTML={{__html: `<SlickVid data-video-id="slickvid" data-thumbnail="19" data-size="500" ></SlickVid>`}}/>
              <pre className="text-size-2">
              {'<SlickVid data-video-id="SKD83J683HDTR7" data-thumbnail="19" data-size="500" ></SlickVid>'}
              </pre>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}




class SampleForm extends React.Component {
  render (){
    const company_key = this.props.company_key;
    const video_id = this.props.video_id;
    const data = this.props.data;

    const textColor = toHex(data.config.color.text);
    return(
      <div className="my-5">
            <form action="#" method="#" className="light-bg-1">
              <h3> Video: {video_id.toUpperCase()}</h3>
              <hr className="" />

              <fieldset>
                <legend><span className="my-3 number">1</span> Configs</legend>
                
                <label>Color:</label><input type="color" value={textColor} onChange={null} className="ml-2" /><br className="" />
                {/* <label>Color - Background:</label><input type="color" value={data.config.color.background} onChange={null} className="pr-2" /><br className="" /> */}

                <label>Name:</label><input className="border" type="text" id="" value="SlickVid teaser"/>
                <label>ID:</label><input className="border" type="text" id="" value="SKD83J683HDTR7"/>
                <label>Thumbnail:</label><input className="border" type="text" id="" value="19"/>
                <label>Size:</label><input className="border" type="text" id="" value="500"/>
                <label>Theme:</label><input className="border" type="text" id="" value="light"/>

                <label>Font:</label><textarea className="border " id={"_"} rows="9">{JSON.stringify(data.config.font, null , 2)}</textarea>
                <label>Social:</label><textarea className="border " id={"_"} rows="7">{JSON.stringify(data.config.social, null , 2)}</textarea>
                <label>End:</label><textarea className="border " id={"_"} rows="5">{JSON.stringify(data.config.end, null , 2)}</textarea>

              </fieldset>

              <fieldset>  
                <legend><span className="my-3 number">2</span> Scenes</legend>

                {data.scenes.map(function(scene, idx){
                  return (
                    <div className="my-3 ">
                      <h5>Scene {idx}</h5>
                      <textarea className="border " id={"_"+idx} rows="9">{JSON.stringify(scene, null , 2)}</textarea>
                      <hr className="" />
                    </div>
                  )
                })}

              </fieldset>


              <button className="my-3 add" type="submit"><i className="fas fa-plus mr-2 "></i>Add</button>
              <button className="my-3 save" type="submit"><i className="fas fa-save mr-2"></i>Save</button>

            </form>

      </div>
    )
  }
}

export default withRouter(Page);
