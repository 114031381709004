import React from "react";

import {Title} from 'component/global/title';


const USPDetails = {
  tagline: 'Create high-impact interactive videos instantly',
  description: "Collecting and managing leads from your website was a complex, expensive process — until now.",
  comparisons: [
    {
      desc: 'Convert messaging into highly engaging videos.', 
      title: 'Creation', 
      icon:'fa fa-video', 
      strength: ['', 'Takes <5 minutes'], 
      weakness: ['', 'Time consuming', 'Slow', 'Expensive'],
    },
    {
      desc: 'Add forms and links inside the videos', 
      title: 'Interactivity', 
      icon:'fas fa-hand-pointer', 
      strength: ['✔', 'Add links in your video', 'Lead gen forms'], 
      weakness: ['✘', ],
    },
    {
      desc: 'Advanced analytics for optimizing impact', 
      title: 'Analytics', 
      icon:'fas fa-chart-line', 
      strength: ['✔', 'Clicks per scene', 'Signup counts', 'and more ...'], 
      weakness: ['', 'Pretty basic ', 'views etc..'],
    },
    {
      desc: 'Change the content dynamically!', 
      title: 'Customizations', 
      icon:'fa fa-cog', 
      strength: ['🎉', 'Subscription management', 'Template library', 'personalization options', ''], 
      weakness: ['✘'],
    },
  ],
}

export default function USPComponent(props){
  const is_mobile = window.innerWidth < 640;
  const usp_details = USPDetails;
  
  return (
    <div id="cta" className="content-container py-5">
      <div className="mx-1 py-5">
        
        <Title type="section" underline='primary' className={"text-center white-text-1 " + ((is_mobile)? 'text-size-10': 'text-size-14')} >{usp_details.tagline}</Title>

        <div className="">
          <div className="py-5">

            <div className={"text-center p-5 black-text-10 " + ((is_mobile)? 'text-size-3': 'text-size-4')}>{usp_details.description}</div>

            <div className={"white-bg-1 rounded " + ((is_mobile)? '': 'mx-5')} style={{overflow: 'scroll'}}>
              <table className=" text-center " style={{margin: `10px auto 10px auto`}}>
                <tbody>
                  <tr>
                    <th scope="col"></th>
                    <td className="text-size-10 p-3 strong white-text-1 primary-bg-1 px-5 ">Our Approach</td>
                    <td className="text-size-10 p-3 strong black-text-3 px-5">Traditional</td>
                  </tr>
                  {usp_details.comparisons.map(function(comparison, idx){
                    return (
                      <tr className={"  " + ((is_mobile)? 'text-size-3': 'text-size-5')} key={''+idx}>
                        <th className={" m-1 p-2 " + ((is_mobile)? ' ': ' ') + ((idx%2===0)? ' dark-highlight-1 ':'') } style={{textAlign: 'left', minWidth: '170px'}}>
                          <span className="text-size-5">
                            <i className={" black-text-3 m-1 px-1 py-3 mr-2 " + comparison.icon}></i>{comparison.title}
                          </span>
                          <div className={"text-size-3 black-text-7 " + ((is_mobile)? ' ': 'p-2')}>{comparison.desc}</div>
                        </th>
                        <td className={"align-middle white-text-1  "+ ((idx%2===1)? ' primary-bg-1 ':' primary-bg-2 ') + ((is_mobile)? 'text-size-2': 'm-1 text-size-3')}>
                          {comparison.strength.map(function(line, idx){
                            return (<div key={`k_${idx}`} className={" my-1 " + ((idx === 0)? ' text-size-12': ' text-size-3')}>{line}</div>)
                          })}
                        </td>
                        <td className={"align-middle black-text-3 " + ((idx%2===0)? ' dark-highlight-1 ':'')  + ((is_mobile)? ' text-size-3': ' m-1 text-size-3')}>
                          {comparison.weakness.map(function(line, idx){
                            return (<div key={`k_${idx}`} className={"  my-1 " + ((idx === 0)? ' text-size-12': ' text-size-3')}>{line}</div>)
                          })}
                        </td>
                      </tr>  
                    )
                  })}
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>
  )

}
