import React from 'react';
import Notifications from 'react-notify-toast';
import {Helmet} from "react-helmet";


export default function PageBlankLayout(props) {

  const version = (process.env.NODE_ENV === 'production')? '0.1.20221201.01' : '0.1.20221201.01';
  return (
    <div id={props.id} className={"page-wrapper " + ((props.className)? props.className: '') }>
      <Notifications options={{zIndex: 200, top: '80px'}} />

      {props.children}

      <Helmet>
        <script src={"/lib/slickvid/"+version+"_sv.min.js"}></script>
      </Helmet>

    </div>
  );
}
