import API from 'utils/xhr';

// rename SLICKVID_ to your custom project

function _getFromStorage(key){
  return localStorage.getItem("SLICKVID_" + key);
}

function _setToStorage(key, data){
  localStorage.setItem("SLICKVID_" + key, data);
}

function _removeFromStorage(key){
  localStorage.removeItem("SLICKVID_" + key);
}


export function hasStoredToken(){
  return !!_getFromStorage("token");
}

export function getStoredToken(){
  return _getFromStorage("token");
}

export function saveToken(token){
  _setToStorage("token", token);
  API.defaults.headers['Authorization'] = 'Bearer ' + token;
}

export function deleteToken(){
  _removeFromStorage('token');
  clearLoginMeta();
}


export function saveLoginMeta(meta){
  _setToStorage("user_xid", meta.user_xid);
}

export function clearLoginMeta(meta){
  _removeFromStorage('user_xid');
}


export function getUserId(){
  return _getFromStorage("user_xid");
}


export function getLeadID(){
  var leadId = _getFromStorage("gen_lead_id");
  if (!leadId){
    const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    leadId = `SLICKVID_${genRanHex(16)}`;
    _setToStorage("gen_lead_id", leadId);
  }
  return leadId;
}

