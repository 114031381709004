import React from 'react';
import { withRouter} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import LandingLayout from 'component/layout/page/landing';

import Blogs from           'component/content/blog';
import Hero from            'component/content/hero';
import Logos from            'component/content/logos';
import USP from            'component/content/usp';
import Features from        'component/content/features';
import Testimonials from        'component/content/testimonials';
import CTA from        'component/content/cta';
import {PageCTA as SubscribePageCTA} from  'component/content/subscribe';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="landing-page">
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <Hero />

        {/* <Logos /> */}

        <div className="dark-bg-1 content-section flex-center py-5"  style={{backgroundImage: `url('/static/images/bg/02.svg')`}}>
          <div className="" style={{backdropFilter: 'blur(10px)'}}>
            <Blogs />
          </div>
        </div>

        <div className="py-5 light-bg-1">
        <Features />
        </div>

        <CTA />
  
        {/* <div className="content-section dark-bg-1 " style={{backgroundImage: `url('/static/images/bg/07.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} > 
          <div className="" style={{backdropFilter: 'blur(10px)'}}>
            <USP />
          </div>
        </div> */}


        {/* <CTA /> */}

        {/* <Testimonials /> */}

        {/* <div 
          className="content-section"    
          style={{backgroundImage: `url('/static/images/bg/06.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
        >
          <SubscribePageCTA category="enterprise" inverted />
        </div> */}


      </LandingLayout>
    )
  }
}

export default withRouter(Page);
