import React from "react";

import {Helmet} from 'react-helmet';

import { SITE } from "component/global/constants";


export default function Component(props){

  const seo = props.seo || {};

  const description = seo.description || SITE.description;
  // url: window.location.href,
  const url = window.location.href; // seo.url || SITE.url;
  const title = seo.title || SITE.title;
  const page_image = seo.img || SITE.logo;
  const handle = SITE.handle;
  

  return (
    <Helmet>
      <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />

      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      <meta http-equiv="Content-Language" content="en" />
      <meta name="description" content={description} />
      {/* <meta name="google" content="notranslate"/> */}
      {/* <meta name="Description" content={description} /> */}

      <title>{title}</title>
      
      <link rel="canonical" href={url} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:site" content={handle.tw} />
      <meta name="twitter:creator" content={handle.tw} />
      {/* <meta name="twitter:image" content={page_image} /> */}
      {/* <meta name="twitter:description" content={description} /> */}

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={page_image.png} />
      <meta property="og:image:secure_url" content={page_image.png} />
      {/* <meta property="og:image:width" content="1280" /> */}
      {/* <meta property="og:image:height" content="720" /> */}

      {/* <meta name="twitter:card" content="summary" /> */}
      {/* <meta property="og:url" content="http://bits.blogs.nytimes.com/2011/12/08/a-twitter-for-my-sister/" /> */}
      {/* <meta property="og:title" content="A Twitter for My Sister" /> */}
      {/* <meta property="og:description" content="In the early days, Twitter grew so quickly that it was almost impossible to add new features because engineers spent their time trying to keep the rocket ship from stalling." /> */}
      {/* <meta property="og:image" content="http://graphics8.nytimes.com/images/2011/12/08/technology/bits-newtwitter/bits-newtwitter-tmagArticle.jpg" /> */}

    </Helmet>
  )
}

